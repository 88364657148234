import { DrupalParagraph} from "next-drupal"
import {processHTML} from "../../lib/utils";
import classNames from "classnames";
import config from "site.config";

interface ParagraphColumnProps {
    paragraph: DrupalParagraph
}

export function ParagraphColumn({ paragraph, ...props }: ParagraphColumnProps) {
    if( config.debug) {
        console.log('ParagraphTab');
        console.log(paragraph)
    }
    return (
        <>
            <h3>{paragraph.field_title}</h3>
            <div key={paragraph.id} dangerouslySetInnerHTML={{ __html: processHTML(paragraph.field_html?.processed) }} className={classNames("wysiwyg",paragraph.type)} />
        </>
    )
}

import { DrupalParagraph} from "next-drupal"
import {processHTML} from "../../lib/utils";
import classNames from "classnames";
import config from "site.config";

interface ParagraphHtmlProps {
    paragraph: DrupalParagraph
}

export function ParagraphHtml({ paragraph, ...props }: ParagraphHtmlProps) {
    if( config.debug) {
        console.log('ParagraphHtml');
        console.log(paragraph)
    }
    return (
        <div key={paragraph.id} dangerouslySetInnerHTML={{ __html: processHTML(paragraph.field_html?.processed) }} className={classNames("wysiwyg",paragraph.type)} />
    )
}

import Image from "next/image"
import {DrupalParagraph} from "next-drupal"
import {processHTML} from "../../lib/utils";
import { absoluteUrl } from "lib/utils"
import { useState, useEffect } from 'react';
import { drupal } from "lib/drupal";
import classNames from "classnames";
import config from "site.config";

interface ParagraphBpImageHtmlProps {
    paragraph: DrupalParagraph
}

export function ParagraphBpImageHtml({ paragraph, ...props }: ParagraphBpImageHtmlProps) {
    const [imageData, setImageData] = useState({
        alt : "",
        height: 480,
        width: 768,
        url : ""
    });
    const [loading, setLoading] = useState(true);

    const classes = classNames(
        "bp_par--wrapper",
        paragraph.field_par_set_classes,
        paragraph.field_par_set_color
    );

    useEffect(() => {
        async function fetchData() {
            try {
                const image = await drupal.getResource(
                    "media--image",
                    paragraph.field_image.id
                );
                const file = await drupal.getResource(
                    "file--file",
                    image.field_media_image.id
                );
                setImageData({
                    alt: image.field_media_image.resourceIdObjMeta?.alt,
                    width: image.field_media_image.resourceIdObjMeta?.width,
                    height: image.field_media_image.resourceIdObjMeta?.height,
                    url: file?.uri?.url
                });
                setLoading(false);
            } catch (error) {
                console.error('Image loading error', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [paragraph.field_image]);

    return (
        <div key={paragraph.id}  className={classNames("wysiwyg", paragraph.type, "bp_par")}>
            <div className={classes}>
                <div className="bp_par--container">
                    <div className="bp_par--image">
                        {loading ? (
                            <div className="img-placeholder"></div>
                        ) : (
                            <Image
                                src={absoluteUrl(imageData.url)}
                                width={imageData.width}
                                height={imageData.height}
                                alt={imageData.alt}
                            />
                        )}
                    </div>
                    <div className="bp_par--html" dangerouslySetInnerHTML={{ __html: processHTML(paragraph.field_html?.processed) }} />
                </div>
            </div>
        </div>

    )
}



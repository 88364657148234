import Image from "next/image"
import {DrupalParagraph} from "next-drupal"

import { absoluteUrl } from "lib/utils"
import config from "site.config";
import { useState, useEffect } from 'react';
import { drupal } from "lib/drupal"

interface ParagraphImageProps {
    paragraph: DrupalParagraph
}

export function ParagraphImage({ paragraph, ...props }: ParagraphImageProps) {
    const [imageData, setImageData] = useState({
        alt : "",
        url : ""
    });

    useEffect(() => {
        async function fetchData() {
            try {
                const image = await drupal.getResource(
                    "media--image",
                    paragraph.field_image.id
                );
                const file = await drupal.getResource(
                    "file--file",
                    image.field_media_image.id
                );
                setImageData({
                    alt: image.field_media_image.resourceIdObjMeta?.alt,
                    url: file?.uri?.url
                });
            } catch (error) {
                console.error('Image loading error', error);
            }
        };

        fetchData();
    }, [paragraph.field_image]);


    return (
        <figure className="my-4" key={paragraph.id}>
            <Image
                src={absoluteUrl(imageData.url)}
                width={768}
                height={480}
                alt={imageData.alt}
            />
        </figure>
    )
}



import {DrupalParagraph} from "next-drupal"
import process from "process";
import ReactPlayer from "react-player";
import config from "site.config";

interface ParagraphVideoProps {
    paragraph: DrupalParagraph
}

export function ParagraphVideo({ paragraph, ...props }: ParagraphVideoProps) {
    if( config.debug ) {
        console.log('ParagraphVideo');
        console.log(paragraph)
    }
    return (
        <>
            { paragraph?.field_image?.field_media_oembed_video ?
            <ReactPlayer
                url={paragraph.field_image.field_media_oembed_video}
                key={paragraph.field_image.id}
                controls={true}
            />:
                null
            }
        </>
    )
}



import { DrupalParagraph} from "next-drupal"
import {processHTML} from "../../lib/utils";
import classNames from "classnames";
import config from "site.config";

interface ParagraphBpHtmlProps {
    paragraph: DrupalParagraph
}

export function ParagraphBpHtml({ paragraph, ...props }: ParagraphBpHtmlProps) {
    if( config.debug) {
        console.log('ParagraphBpHtml');
        console.log(paragraph)
    }

    const classes = classNames(
        "bp_par--wrapper",
        paragraph.field_par_set_classes,
        paragraph.field_par_set_color
    );
    return (
        <div key={paragraph.id}  className={classNames("wysiwyg", paragraph.type, "bp_par")}>
            <div className={classes}>
                <div className="bp_par--container" dangerouslySetInnerHTML={{ __html: processHTML(paragraph.field_html?.processed) }} />
            </div>
        </div>

    )
}

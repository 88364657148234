import Head from "next/head"
import { DrupalNode} from "next-drupal"
import { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { drupal } from "lib/drupal"
import { Layout, LayoutProps } from "components/layout/layout"
import {DrupalJsonApiParams} from "drupal-jsonapi-params";
import { useTranslation } from "next-i18next";
import { getGlobalElements } from "lib/get-global-elements";
import * as process from "process";
import {NodeExpertize} from "../components/nodes/node--expertize";
import {NodeCampaignsList} from "../components/nodes/node--campaigns--list";
import {NodeCampaignsSlider} from "../components/nodes/node--campaigns--slider";
import {getParams} from "../lib/get-params";
import { useScroll } from '@react-spring/web';
import {Meta} from "../components/meta";

export interface IndexPageProps extends LayoutProps {
    expertizes: DrupalNode[],
    gridCampaigns: DrupalNode[]
}

export default function IndexPage({ expertizes, gridCampaigns, menus }: IndexPageProps) {
    const { t, i18n } = useTranslation('common');
    const currentLang = i18n.language;
    let [posts, setPosts] = useState([]);
    let [selected, setSelected] = useState('')
    let [expertizeContent , setExpertize] = useState(null);


    const loadCampaigns = async (e, currentLang,  expertizeId) => {
        let params_campaign = getParams("node--campaign", "teaser", false)
        params_campaign.addFilter('status',"1");
        //params_campaign.addFilter('promote',"1");
        params_campaign.addSort("field_position", "ASC")
        params_campaign.addSort("created", "DESC")
        if(expertizeId) {
            params_campaign.addFilter("field_expertize.id", expertizeId);
        } else {
            params_campaign.addFilter("field_promote_to_all_campaigns", "1");
        }
        const campaigns = await drupal.getResourceCollection(
            "node--campaign",
            {
                params: params_campaign.getQueryObject(),
                locale: currentLang
            }
        );
        if(expertizeId) {
            setSelected(expertizeId);
            let params_expertize = new DrupalJsonApiParams();
            params_expertize.addFilter('status', "1");
            params_expertize.addFields("node--expertize", ["title", "body", "field_paragraphs"]);
            params_expertize.addInclude(["field_paragraphs"])
            params_expertize.addFilter("id", expertizeId);

            const expertize_detail = await drupal.getResource(
                "node--expertize",
                expertizeId,
                {
                    params: params_expertize.getQueryObject(),
                    locale: currentLang
                }
            );
            setExpertize( expertize_detail);
        } else {
            if (localStorage.getItem("selected_category") == "") {
                setSelected('')
                setExpertize( null);
            }
        }
        setPosts( campaigns );
    };

    useEffect(() => {
        loadCampaigns(null, currentLang, '');
    }, [i18n]);

    const mainContent = useRef(null);
    const redClaimBox = useRef(null);
    const showGridRef = useRef(false);

    useEffect(() => {
        let showGrid = false;
        let gotBack = false;

        const localSelected = localStorage.getItem("selected_category");
        if (localStorage.getItem("get_back") === "true") {
            localStorage.removeItem("get_back");
            gotBack = true;
        }

        if (localSelected !== "") {
            setSelected(localSelected);
            loadCampaigns("", currentLang, localSelected);
        }

        if (window.innerWidth > 768 && !localSelected && !gotBack) {
            showGrid = true;
            document.body.classList.add("menu_transparent");
            document.body.classList.remove("no_splash");
        } else {
            document.body.classList.remove("menu_transparent");
            document.body.classList.add("no_splash");
        }
        showGridRef.current = showGrid;
    }, []);

    useLayoutEffect (() => {
        const mainContentEl = mainContent.current;
        if (document.documentElement.scrollTop !== 0 && mainContentEl) {
            mainContentEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [mainContent.current]);


    const scrollY = useScroll({
        onChange: ({value: {scrollY}}) => {
            if (showGridRef.current) {
                const redClaimEl = redClaimBox.current;
                if (redClaimEl) {
                    if (scrollY > redClaimEl.offsetTop) {
                        redClaimEl.classList.add("branding-sticky");
                        redClaimEl.style.height = window.innerHeight + 'px';
                    } else {
                        redClaimEl.classList.remove("branding-sticky");
                        redClaimEl.style.height = '';
                    }
                }
                const mainContentEl = mainContent.current;
                if (mainContentEl) {
                    const mainContentEl_rect = mainContentEl.getBoundingClientRect();
                    if (mainContentEl_rect.top > 0) {
                        document.body.classList.add("menu_transparent");
                        if (mainContentEl_rect.top < 400) {
                            let menuTransition = ((400 - (mainContentEl_rect.top)) / 4).toString();
                            document.body.style.setProperty('--menu-transition', menuTransition);
                        } else {
                            document.body.style.setProperty('--menu-transition', '0');
                        }
                    } else {
                        document.body.classList.remove("menu_transparent");
                        document.body.style.setProperty('--menu-transition', '0');
                    }
                }
            }
        },
        default: {
            immediate: true,
        },
    });

    let tags = [];
    tags.push({
        tag: 'title',
        attributes: {
            name: 'title',
            content: t("metatitle")
        }
    });
    tags.push({
        tag: "meta",
        attributes: {
            property: "og:image",
            content: `${process.env.NEXT_PUBLIC_URL}/images/layout/logo.svg`
        }
    });
    tags.push({
        tag: "meta",
        attributes: {
            property: "og:image:width",
            content: 800
        }
    });
    tags.push({
        tag: "meta",
        attributes: {
            property: "og:image:height",
            content: 600
        }
    });

    return (
        <Layout menus={menus}>
            <Meta title={t("metatitle")} tags={tags} />
            {showGridRef.current &&
                <>
                    <div className="homepage--red_grid">
                        <div className="homepage--red_grid--inner">
                            <NodeCampaignsSlider campaigns={gridCampaigns} />
                        </div>
                    </div>
                    <div ref={redClaimBox} className="homepage--red_claim">
                        <div className="homepage--red_claim--inner">
                            <div className="homepage--red_claim--text">
                                <p>{ t("red_claim") }</p>
                            </div>
                        </div>
                    </div>
                </>
            }

            <div className="homepage--container_wrapper">
                <div className="container">
                    <div ref={mainContent} id="campaigns">
                        <br/>
                        {expertizes?.length &&
                        (
                            <ul className="category-menu">
                            {expertizes.map((expertize) => {
                                return <li key={expertize.id} className={selected == expertize.id ? "active" : undefined}><a onClick={(e) => {
                                    localStorage.setItem("selected_category", expertize.id);
                                    loadCampaigns(e, currentLang, expertize.id);
                                }}>{expertize.title}</a></li>
                            })}
                            </ul>
                        )}
                        <div id="expertize_content">
                            {expertizeContent != null ? (
                                <NodeExpertize loadCampaigns={loadCampaigns} node={expertizeContent} />
                            ):''}
                        </div>
                        <div id="campaigns_list">
                            <NodeCampaignsList campaigns={posts} />
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    )
}

export async function getServerSideProps(
    context
) {
    /**
     * list of expertizes
     */
    let params_expertize = new DrupalJsonApiParams();
    params_expertize.addFilter('status',"1");
    params_expertize.addFields("node--expertize",["title"]);

    const expertizes = await drupal.getResourceCollectionFromContext(
        "node--expertize",
        context,
        {
            params: params_expertize.getQueryObject(),
        }
    );

    let params_grid_campaigns = getParams("node--campaign", "slider", false)
    params_grid_campaigns.addFilter('status',"1");
    params_grid_campaigns.addFilter('promote',"1");
    params_grid_campaigns.addSort("field_position", "ASC")
    params_grid_campaigns.addSort("created", "DESC")
    params_grid_campaigns.addFilter("field_promote_to_all_campaigns", "1");

    const grid_campaigns = await drupal.getResourceCollectionFromContext(
        "node--campaign",
        context,
        {
            params: params_grid_campaigns.getQueryObject(),
        }
    );

    return {
        props: {
            ...(await getGlobalElements(context)),
            expertizes: expertizes,
            gridCampaigns: grid_campaigns
        },
    }
}

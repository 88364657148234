import { DrupalParagraph} from "next-drupal"
import {ParagraphHtml} from "./paragraph--html";
import {ParagraphSlogan} from "./slogan";
import {ParagraphImage} from "./paragraph--image";
import config from "site.config";
import {ParagraphVideo} from "./paragraph--video";
import React, {useEffect, useState} from "react";
import {ParagraphTab} from "./paragraph--tab";
import {ParagraphColumn} from "./paragraph--column";
import {ParagraphBpHtml} from "./paragraph--bp_html";
import {ParagraphBpImage} from "./paragraph--bp_image";
import {ParagraphBpImageHtml} from "./paragraph--bp_image_html";
import classNames from "classnames";

interface ParagraphsProps {
    paragraphs: DrupalParagraph[]
}

export function Paragraphs({ paragraphs, ...props }: ParagraphsProps) {
    if( config.debug) {
        console.log('Paragraphs');
        console.log(paragraphs);
    }

    const [showTab, setShowTab] = React.useState<number>(0)
    let [selected, setSelected] = useState(-1)

    /*const setShowTab = (id) => {
        document.querySelector('.paragraph-tab').style.display = 'none';
        document.querySelector('#tab-'+id).style.display = 'block';
    }*/

    const manageTab = async (e, tabIndex) => {
        /*console.log(tabIndex);
        document.querySelector('.paragraph-tab').style.display = 'none';
        document.querySelector('#tab-'+tabIndex).style.display = 'block';
         */
        setSelected(tabIndex);
    };


    const tabs = paragraphs.map( (paragraph: DrupalParagraph, index: number ) => {
        if (paragraph.type == 'paragraph--paragraphs_tab') {
            return (<li id={paragraph.id} key={'tab-link-'+paragraph.id} className={classNames( index == selected ? "active" : undefined)} onClick={(e) => manageTab(e, index)}>{paragraph.field_title}</li>);
        }
    });

    useEffect(() => {
        if(tabs.length  > 0 ) {
            manageTab(null, -1);
        }
    }, []);

    return (<>
            {
                paragraphs.map((paragraph: DrupalParagraph, index:number ) => {
                    switch (paragraph.type) {
                        case 'paragraph--paragraphs_image':
                            return (<ParagraphImage paragraph={paragraph} key={paragraph.id}/>)
                        case 'paragraph--paragraphs_video':
                            return (<ParagraphVideo paragraph={paragraph} key={paragraph.id}/>)
                        case 'paragraph--paragraphs_tab':
                            return (<ParagraphTab paragraph={paragraph} index={index} selected={selected} key={paragraph.id}/>)
                        case 'paragraph--paragraphs_show_tabs':
                            return (<ul key={paragraph.id} id="paragraph_tabs">{tabs}</ul>);
                        case 'paragraph--paragraph_html':
                            return (<ParagraphHtml paragraph={paragraph} key={paragraph.id}/>)
                        case 'paragraph--bp_html':
                            return (<ParagraphBpHtml paragraph={paragraph} key={paragraph.id}/>)
                        case 'paragraph--bp_image':
                            return (<ParagraphBpImage paragraph={paragraph} key={paragraph.id}/>)
                        case 'paragraph--bp_image_html':
                            return (<ParagraphBpImageHtml paragraph={paragraph} key={paragraph.id}/>)
                        case 'paragraph--paragraphs_columns':
                            return (<ParagraphColumn paragraph={paragraph} key={paragraph.id}/>)
                        default:
                            return (<div key={paragraph.id} dangerouslySetInnerHTML={{ __html: paragraph.field_html?.processed }} className="mt-6 font-serif text-xl leading-loose prose" /> )
                    }
                })
            }
        </>
    );
}

import {DrupalNode} from "next-drupal";
import {NodeCampaignsGridContainer} from "./node--campaigns-grid-container";

interface NodeCampaignsListProps {
    campaigns: DrupalNode[]
}

export function NodeCampaignsList({ campaigns }: NodeCampaignsListProps) {
    const perChunk = 19 // items per chunk
    const campaigns_lists = campaigns.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index/perChunk)
        if(!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
    }, [])

    return (
        <>
            {campaigns_lists?.length > 0 && (
                campaigns_lists.map((campaigns_list, index) => {
                        return (<NodeCampaignsGridContainer campaigns={campaigns_list} key={index}/> )
            }))}
        </>
    )
}
import Link from "next/link"
import Image from "next/image";
import {DrupalNode} from "next-drupal";
import {absoluteUrl} from "../../lib/utils";

interface NodeCampaignsGridContainerProps {
    campaigns: DrupalNode[]
}

export function NodeCampaignsGridContainer({ campaigns }: NodeCampaignsGridContainerProps) {
    let gridClass = 'grid-container';
    return (
        <div className="grid-container">
            {campaigns?.length > 0 && (
                campaigns.map((node,index) => {
                    let campaign_link = node.path?.alias ? node.path.alias:'/node/'+node.drupal_internal__nid;
                    let image = node.field_hp_image.field_media_image?.image_style_uri?.wide ? node.field_hp_image.field_media_image.image_style_uri.wide : absoluteUrl(node.field_hp_image.field_media_image.uri.url);
                    return (<div className={"campaign-item grid-item-"+(index+1)} key={"grid-item-"+(index+1)}>
                        <Link href={campaign_link} title={node.title}>
                        <img src={image} alt={node.title}/>

                        <div className="campaign-item-inner">
                            <p>{node.title}</p>
                            <h2>{node.field_client}</h2>
                        </div>
                        </Link>
                    </div>)
                })
            )}
        </div>
    )
}

import { DrupalNode } from "next-drupal"
import {processHTML} from "../../lib/utils";
import React from "react";
import {Paragraphs} from "../paragraph/paragraphs";
import config from "tailwindcss/defaultConfig";
import {useTranslation} from "next-i18next";

interface NodeExpertizeProps {
    node: DrupalNode,
    loadCampaigns: any
}

export function NodeExpertize({ node, loadCampaigns, ...props }: NodeExpertizeProps) {
    if( config.debug) {
        console.log('NodeExpertize');
        console.log(node);
    }
    const { t, i18n } = useTranslation('campaign');
    const currentLang =  i18n.language
    if( node.id) {
        return <div {...props}>
            <h2 onClick={(e) => {
                localStorage.setItem("selected_category", "");
                loadCampaigns(e, currentLang, null);
            }} className="campaign-expertise--label">{t("Back")}</h2>
            {node.body?.processed && <div className="campaign-expertise--intro wysiwyg" key="body"
                    dangerouslySetInnerHTML={{ __html: processHTML(node.body?.processed) }}
                />}
            { node.field_paragraphs.length > 0 &&
                <Paragraphs paragraphs={node.field_paragraphs} key="paragraphs"/>
            }
        </div>
    } else {
        return null;
    }
}

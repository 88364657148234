import { DrupalParagraph} from "next-drupal"
import config from "site.config";
import {processHTML} from "../../lib/utils";
import classNames from "classnames";

interface ParagraphTabProps {
    paragraph: DrupalParagraph,
    index: number,
    selected: number
}

export function ParagraphTab({ paragraph, index, selected, ...props }: ParagraphTabProps) {
    if( config.debug) {
        console.log('ParagraphTab');
        console.log(paragraph)
    }
    return (
        <div key={paragraph.id} id={'tab-'+index} dangerouslySetInnerHTML={{ __html: processHTML(paragraph.field_html?.processed) }} className={classNames("wysiwyg",paragraph.type, index == selected ? "active": undefined) } />
    )
}
